import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ContentSectionMarkdown from "../components/homepage/ContentSection-md"
import words from "lodash/words"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${words(tag.split("/"))}"`
  return (
    <Layout>
      <hr style={{ background: "white" }} />
      <ContentSectionMarkdown
        title={tagHeader}
        // text={tagHeader}
        data={data.allMarkdownRemark}
        lg={3}
        xs={12}
        sm={4}
        height="180px"
        style={{ image: "contain", height: "180px" }}
      />
    </Layout>
  )
}
export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { regex: $tag } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
            content
            preparationTime
            serving
            category
            region
            tags
            suggested_articles
            suggested_recipes
            suggested_wines
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
